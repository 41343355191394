<template>
  <div class="forgot-password vh-100">
    <v-container
      fill-height
      style="position: absolute; top: 0; bottom: 0; left: 0; right: 0; z-index: 9999; margin: auto"
    >
      <v-row justify="center" style="margin: 0 auto">
        <v-container>
          <v-row>
            <v-col sm="10" md="8" offset-md="2" offset-sm="1">
              <v-card elevation="4" class="mt-0">
                <app-public-header :showHome="false" :showBack="true" />
                <v-divider></v-divider>
                <v-card-text>
                  <h2>{{ $t("v_resetpassword.t_password_reset") }}</h2>
                  <h4 class="mt-2">
                    {{ $t("v_resetpassword.t_enter_email_to_request_a_password_reset") }}
                  </h4>
                  <v-alert
                    v-if="hasNotification"
                    :type="notificationType"
                    class="ma-4"
                    v-html="notificationMessage"
                  ></v-alert>
                  <v-form v-else v-model="emailFormValid" @submit.prevent="onEmailFormSubmit" class="my-6">
                    <v-text-field
                      v-model="fields.email"
                      ref="emailField"
                      outline
                      :label="$t('v_resetpassword.t_email')"
                      type="text"
                      name="reset-email"
                      :rules="[rules.email.required, rules.email.valid]"
                    ></v-text-field>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" type="submit" large width="100%" class="mt-4" :disabled="!emailFormValid">
                      <span>{{ $t("v_resetpassword.t_request_reset") }}</span>
                    </v-btn>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { UserService } from "@services";
import AppPublicHeader from "@/components/AppPublicHeader";

export default {
  name: "ForgotPassword",
  metaInfo: {
    title: "Forgot Password",
  },
  components: {
    AppPublicHeader,
  },
  data() {
    return {
      //login: {
      emailFormValid: true,
      rules: {
        email: {
          required: (v) => !!v || this.$t("v_resetpassword.t_val_req_email_required"),
          valid: (v) => /.+@.+\..+/.test(v) || this.$t("v_resetpassword.t_val_email_valid"),
        },
      },
      fields: {
        email: "",
      },
      hasNotification: false,
      notificationType: "",
      notificationMessage: "",
    };
  },
  computed: {
    ...mapGetters("settings", ["settings"]),
  },

  methods: {
    onEmailFormSubmit() {
      this.hasNotification = true;
      this.notificationType = "success";
      this.notificationMessage = this.$t("v_resetpassword.t_if_email_matches_records_expect_email");
      UserService.forgotPassword({
        email: this.fields.email,
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.emailField.focus();
    });
  },
};
</script>
<style lang="sass">
.forgot-password
  width: 100%
.bg-dark-trans
  background: rgba(0, 0, 0, 0.8)
</style>
